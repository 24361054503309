import Common from "./common.js"
import 'parsleyjs'
import $ from "jquery/dist/jquery.js"

class Home extends Common {
  constructor() {
    super();
    var CI = this;
    CI.checkCookieExist()
    this.validate("#loanform");

    $(document).scroll(function() {
      var y = $(this).scrollTop();
      if (y > 600) {
        $('.floting-icon').fadeIn();
      } else {
        $('.floting-icon').fadeOut();
      }
    });

    $(document).ready(function() {
      // jQuery.noConflict();
      var popup = document.getElementById('cookiespopup')
      if (popup) {
        $("#cookiespopup").style.display="block"
        var email = CI.getUrlParameter('email')
        $('.pre-pop').val(email)
      }
    });

    $( ".close-b" ).click(function() {
      $('.modal2').hide();
    })

    $( ".accept-cooky" ).click(function() {
      CI.CookieEvent()
      $.ajax({
        url: "/set_cookies",
        success: function(){
        },
      })
      $('.cookie-consent').addClass('d-none')
    })
    $('.reject-cookie').click(function(){
      $('.cookie-consent').addClass('d-none')
    })

    $('.partner-text').click(function(){
      $('.modal2').show();
    })

    $('#customCheck1').click(function(){
      CI.ToggleCheckBox()
    })

    $( ".popup-submit" ).click(async (event) => {
      // event.preventDefault();
      $('#loanform').parsley().whenValidate({
        group: 'block-0'
      }).done(async () => {
        $('.leadsLoader').height('100%');
        $('.loan_duration_months').val($("input[name='loan_duration_months']:checked").val())
      })
    })

    $('.source').val(this.getUrlParameter('source') || "")
    $(".optinUrl").val(this.getUrlParameter("tPar[optIn]") || "https://get-a-loan.today/")
  }

  getCookie(name) {
    var dc = document.cookie;
    var prefix = name + "=";
    var begin = dc.indexOf("; " + prefix);
    if (begin == -1) {
      begin = dc.indexOf(prefix);
      if (begin != 0) return null;
    }
    else
    {
      begin += 2;
      var end = document.cookie.indexOf(";", begin);
      if (end == -1) {
        end = dc.length;
      }
    }
    return decodeURI(dc.substring(begin + prefix.length, end));
  }

  checkCookieExist() {
    var myCookie = this.getCookie("_msuuid_55417an066410");
    if (myCookie == null) {
      $('.cookie-consent').removeClass('d-none')
    }
  }

  CookieEvent(){
    dataLayer.push({'event': 'cookieconsent'})
  }

  ToggleCheckBox(){
    var chk2 = $("input[type='checkbox'][name='partners-input']");
    chk2.prop("checked", !chk2.prop("checked"));
  }
}
export default new Home();
